<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Software Development Supervisor</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Software Development Supervisor</p>
            <div class="description-container">
                <p class="subtitle"> Job Responsibilities</p>
                <ul class="requirement-list">
                    <li class="list-details"> Overall technical success of a project and work with the product owner to define the project scope, and then with the backend developers, mobile developers, and outsources to ensure that the project is completed on time.</li>
                    <li class="list-details"> To plays a key role in risk management and identify and assess risks, and then develop mitigation plans to reduce the likelihood or impact of those risks.</li>
                    <li class="list-details"> Communication and coordination and keep the team informed of progress, and they resolve any issues that arise.</li>
                    <li class="list-details"> Define the project scope and requirements with a team as of project plan, budget, project progress, identify risks, and other required quality standards.</li>
                    <li class="list-details"> Communicate with the team vender and stakeholders to make sure smoothly and efficiencies.</li>
                    <li class="list-details"> Other tasks assign by line manager.</li>
                </ul>
                <p class="salary">SALARY: NEGOTIABLE</p>
                <p class="subtitle">Job Requirements</p>
                <ul class="requirement-list">
                    <li class="list-details"> Bachelor degree in Computer Science or Information Technology.</li>
                    <li class="list-details"> At least 2years background in project lead and manager.</li>
                    <li class="list-details"> Excellent communication and interpersonal skills.</li>
                    <li class="list-details"> Ability to work independently and as part of a team.</li>
                    <li class="list-details"> Ability to manage multiple projects simultaneously.</li>
                    <li class="list-details"> Good attitude and teamwork.</li>
                    <li class="list-details"> Good communication in Khmer & English, and Chinese is an advantage.</li>
                </ul>
            </div>

            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;

        .list-details {
            margin-bottom: 0.5%;
            display: list-item;
            list-style-type: disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}
.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
